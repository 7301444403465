<template>
    <div class="live-cam">
        <stream-suggestions v-if="ifMobile" :projects="projects"/>
        <live-chat data-scroll data-scroll-sticky
                   data-scroll-target="#pinSection"
                   :contentData="contentData"
        />
        <div class="pin-section"
             id="pinSection"></div>
        <div class="right-content">
            <live-stream :activeProject="activeProject"/>
            <stream-suggestions :projects="projects"/>
        </div>

    </div>
</template>

<script setup>
import LiveChat from '../LiveChat/LiveChat'
import LiveStream from '../LiveStream/LiveStream'
import StreamSuggestions from '../StreamSuggestions/StreamSuggestions'
import func from "../../../helpers/func";
import {ref, computed} from "vue";
import {onMounted} from "vue";
import {useRoute} from "vue-router";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
const route = useRoute()

const projects = ref([])
let activeProjectSlug = route.params?.path2
let activeProject = ref({})
let ifMobile = computed(() => window.innerWidth < 1024)

const getProjects = async () => {
    let {data} = await func.postData('dev/getProjects')

    projects.value = data?.map(item => ({
        ...item,
        value: item.id,
        label: item.title
    })) || []

    //set active project
    activeProject.value = projects.value?.find(item => item.slug === activeProjectSlug)
}
onMounted(() => {
    getProjects()
})

</script>

<style lang="scss">
.live-cam {
    display: flex;
    padding-bottom: 136px;
    position: relative;
    @media (max-width: 1023px) {
        flex-direction: column-reverse;
        padding: 0 25px;
    }

    .right-content {
        padding: 0 40px 0 60px;
        width: calc(100% - 430px);
        @media (max-width: 1023px) {
            width: 100%;
            padding: 0;
            .stream-suggestion {
                display: none !important;
            }
        }
    }

    .stream-suggestion {
        display: block;
        @media (max-width: 1023px) {
            padding-bottom: 40px;
        }
    }

    .pin-section {
        position: absolute;
        height: calc(100% - 100px);
        top: -250px;
        pointer-events: none;
    }

}
</style>
